import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { RcButton } from '@rc-d2c/shared-ui/RcButton';
import styles from '../../styles/common.module.scss';

type Props = {
  label: string;
  path: string;
  version?: string;
};

type Ref = HTMLDivElement;

const NavLink = React.forwardRef<Ref, Props>(function NavLink({ label, path, version }: Props, ref) {
  const { push, pathname } = useRouter();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(pathname === path);
  }, [pathname, path]);

  return (
    <div ref={ref} className={styles.navLink}>
      <RcButton customClasses={active ? styles.activeLink : ''} onClick={() => push(path)} label={label} variant="hyperlink" />
      <small className={styles.version}>{version}</small>
    </div>
  );
});

export default NavLink;
