import { EVENTS, PubSub } from '@rc-d2c/context-manager';
import { Session } from 'next-auth';

export const handleLogout = async (session: Session | null) => {
  if (!session) {
    PubSub.publish(EVENTS.SHELL_USER_LOGGED_OUT);
    return;
  }

  const idToken = session?.idToken;
  const postLogoutRedirectURL = process.env.NEXT_PUBLIC_NEXTAUTH_LOGOUT_REDIRECT_URL;
  const canEndOktaSession = !!idToken && !!postLogoutRedirectURL;

  if (canEndOktaSession) {
    const endSessionURL = process.env.NEXT_PUBLIC_OKTA_LOGOUT_URL;
    const endSessionParams = new URLSearchParams({
      id_token_hint: idToken,
      post_logout_redirect_uri: postLogoutRedirectURL,
    });
    window.location.href = `${endSessionURL}?${endSessionParams.toString()}`;
  }
};
