import { EVENTS, PubSub } from '@rc-d2c/context-manager';
import { signIn, useSession } from 'next-auth/react';
import Head from 'next/head';
import React, { PropsWithChildren, useEffect } from 'react';

import Navbar from '../Navbar';
import styles from '../../styles/common.module.scss';
import { handleLogout } from '../../helpers/authentication';

export default function Layout({ children }: PropsWithChildren) {
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.accessToken) {
      PubSub.publish(EVENTS.SET_USER_DATA, {
        accessToken: session?.accessToken,
        profile: session?.user,
      });
    }

    const subscriptions: string[] = [];

    subscriptions.push(
      PubSub.subscribe(EVENTS.RENEW_OKTA_TOKEN, () => {
        signIn('okta');
      }),
    );

    subscriptions.push(
      PubSub.subscribe(EVENTS.USER_EXPIRED_TOKENS, () => {
        handleLogout(session);
      }),
    );

    return () => {
      subscriptions.forEach((subscription) => PubSub.unsubscribe(subscription));
    };
  }, [session]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Navbar />
      <main className={styles.componentsContainer}>{children}</main>
    </>
  );
}
