import { delay, http, HttpResponse } from 'msw';
import pets from './sub_revamp@outlook.fr/pets.json';
import subscriptionsList from './sub_revamp@outlook.fr/subscriptionsList.json';
import subscriptionDetails from './sub_revamp@outlook.fr/subscriptionDetails.json';
import paymentsInfos from './sub_revamp@outlook.fr/paymentsInfos.json';

import translations from '@rc-d2c/context-manager/src/mocks/defaultTranslation.json';
import { APIM_BASE_URLS, ENDPOINTS, ENVIRONMENT_URLS } from '@rc-d2c/context-manager';

export function asRcHandlerPathname(endpoint: string) {
  return `${ENVIRONMENT_URLS.STG}/:country/shop/api${endpoint}`.split('?')[0];
}

export function asApimHandlerPathname(endpoint: string) {
  return `${APIM_BASE_URLS.STG}${endpoint}`;
}

export function removeQueryParams(endpoint: string) {
  return endpoint.replace(/\?.*$/, '');
}
// the order of the handlers is important here:
// if two handlers are matching the same request, the first defined will take priority
export const handlers = [
  http.get('https://d2cshop.blob.core.windows.net/*', () => {
    return HttpResponse.json({
      context: translations,
    });
  }),

  http.get(asApimHandlerPathname(ENDPOINTS.RETRIEVE_PET_INFORMATION), () => {
    return HttpResponse.json(pets);
  }),

  http.get(asRcHandlerPathname(ENDPOINTS.SUBSCRIPTIONS_LIST_LEGACY('sub_revamp@outlook.fr', '123457929')), () => {
    return HttpResponse.json(subscriptionsList);
  }),

  http.get(asRcHandlerPathname(ENDPOINTS.SUBSCRIPTION_DETAILS_LEGACY(':id')), () => {
    return HttpResponse.json(subscriptionDetails);
  }),

  http.get(asRcHandlerPathname(ENDPOINTS.PAYMENT_INFOS_LEGACY(':storeId')), () => {
    return HttpResponse.json(paymentsInfos);
  }),
];
